import { useEffect, useMemo, useState } from "react";
import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../infra/api";
import { Button, Col, Flex, Row, Tag, Tooltip } from "antd";
import TokensSection from "../components/projects/TokensSection";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import ReportsChart from "../components/projects/ReportsChart";
import { Typography } from "antd";
import JobsChart from "../components/projects/JobsChart";

const { Title } = Typography;
export default function ProjectDetailsPage({ user, setUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const isFavorite = useMemo(() => {
    return project?.fav_users.find((favUser) => favUser.id === user.id);
  }, [project, user]);
  const isProjectAdmin = useMemo(() => {
    // ! debug
    // return false;

    return project?.project_users.find(
      (projectUser) =>
        projectUser.user.id === user.id && projectUser.project_role === "ADMIN"
    );
  }, [project, user]);

  useEffect(() => {
    console.log("fetch project details");
    // fetch project details
    setLoading(true);
    api.projects
      .project(id)
      .then((response) => {
        console.log("project details response", response);
        setTimeout(() => {
          setLoading(false);
          setProject(response.data);
        }, 500);
      })
      .catch((error) => {
        console.error("fetch project details error", error);
        setLoading(false);
        if (error.response.status === 401) {
          setUser(null);
        } else if (error.response.status === 404) {
          // project not found
          navigate("/404");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFavorite = () => {
    setLoading(true);
    api.projects
      .toggleFavorite(id)
      .then((response) => {
        console.log("toggle favorite response", response);
        setTimeout(() => {
          navigate(0);
        }, 500);
      })
      .catch((error) => {
        console.error("toggle favorite error", error);
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col span={24}>
        {loading && <h1>{t(TRANSLATIONS.LOADING.key)}</h1>}
        {project && !loading && (
          <Row>
            <Col span={24}>
              <Row>
                <Col span={isProjectAdmin ? 15 : 24}>
                  <Flex gap="4px 0" wrap>
                    {!isFavorite && (
                      <Tooltip
                        title={t(TRANSLATIONS.ADD_FAVORITE.key)}
                        placement="right"
                      >
                        <Button
                          shape="circle"
                          icon={<StarOutlined />}
                          onClick={() => toggleFavorite()}
                        />
                      </Tooltip>
                    )}
                    {isFavorite && (
                      <Tooltip
                        title={t(TRANSLATIONS.REMOVE_FAVORITE.key)}
                        placement="right"
                      >
                        <Button
                          style={{ background: "gold", borderColor: "yellow" }}
                          shape="circle"
                          icon={<StarFilled />}
                          onClick={() => toggleFavorite()}
                        />
                      </Tooltip>
                    )}
                    <Title level={2}>
                      [id: {project.id}] {t(TRANSLATIONS.PROJECT.key)}:{" "}
                      {project.name} (private: {project.private ? "yes" : "no"})
                    </Title>
                  </Flex>
                  <Flex gap="4px 0" wrap>
                    {project.tags.map((tag) => (
                      <Tag
                        color="geekblue"
                        key={tag.id}
                        style={{ margin: "0 5px" }}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Flex>
                  <Title level={4}>{project.description}</Title>

                  <div style={{ margin: "20px" }}>
                    <h2 style={{ paddingLeft: "35px" }}>{t(TRANSLATIONS.JOBS.key)}</h2>
                    <JobsChart project={project} />
                  </div>
                  <div style={{ margin: "20px" }}>
                    <h2 style={{ paddingLeft: "35px" }}>{t(TRANSLATIONS.TESTS.key)}</h2>
                    <ReportsChart project={project} />
                  </div>

                  <Link to={`/reports?project=${id}`}>
                    <Button size="large" shape="round" type="primary">
                      {t(TRANSLATIONS.GO_TO_REPORTS.key)}
                    </Button>
                  </Link>
                  <section id="project-users">
                    <h2>{t(TRANSLATIONS.USERS.key)}</h2>
                    {/* <Link to={`/admin/project-users/?project=${id}`}>
                      <Button
                        size="large"
                        shape="round"
                        type="primary"
                        disabled
                      >
                        {t(TRANSLATIONS.MANAGE_USERS.key)} (todo)
                      </Button>
                    </Link> */}
                    <ul>
                      {project.project_users.map((projectUser) => (
                        <li key={projectUser.user.id}>
                          {projectUser.user.email} ({projectUser.project_role})
                        </li>
                      ))}
                    </ul>
                  </section>
                </Col>
                <Col span={9}>
                  {isProjectAdmin && (
                    <div>
                      <Flex
                        align={"center"}
                        justify={"flex-end"}
                        gap={"middle"}
                      >
                        <Button
                          size="large"
                          shape="round"
                          onClick={() => {
                            navigate(`/projects/${id}/edit`);
                          }}
                        >
                          {t(TRANSLATIONS.EDIT_PROJECT.key)}
                        </Button>
                        <Button
                          size="large"
                          shape="round"
                          danger
                          onClick={() => {
                            if (
                              window.confirm(
                                t(
                                  TRANSLATIONS
                                    .DELETE_PROJECT_CONFIRMATION_MESSAGE.key
                                )
                              )
                            ) {
                              api.projects
                                .deleteProject(id)
                                .then((response) => {
                                  console.log(
                                    "delete project response",
                                    response
                                  );
                                  navigate("/projects");
                                })
                                .catch((error) => {
                                  console.error("delete project error", error);
                                });
                            }
                          }}
                        >
                          {t(TRANSLATIONS.DELETE_PROJECT.key)}
                        </Button>
                      </Flex>

                      {/* TODO: show / hide */}
                      <TokensSection project={project} />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
