import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Empty,
  Flex,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import t from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import api from "../../infra/api";
import UseForm from "../../hooks/useForm";
import useQueryParams from "../../hooks/useQueryParams.hook";
import { useCollapse } from "react-collapsed";

const ProjectsTableSection = ({ user, path }) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { form: filterForm, formFields: filterFormFields } = UseForm({
    NAME_CONTAINS: "name_contains",
    TAGS_MATCH_ALL: "tags_match_all",
    TAGS_MATCH_ANY: "tags_match_any",
    FAV: "fav",
  });
  const [projectTags, setProjectTags] = useState([]);

  useEffect(() => {
    console.log("ProjectsTableSection updated...");
    console.log("queryParams", queryParams);
    setPagination({
      pageSize: queryParams.pageSize ?? 10,
      current: queryParams.page ?? 1,
      total: 0,
    });
    setOrderBy(queryParams.orderBy ?? "-id");
    const filters = {};
    const name_contains = queryParams.name_contains ?? "";
    if (name_contains) {
      filterForm.setFieldValue(filterFormFields.NAME_CONTAINS, name_contains);
    }
    filters.name_contains = name_contains;
    const tags_match_all = queryParams.tags_match_all ?? "";
    if (tags_match_all) {
      filterForm.setFieldValue(
        filterFormFields.TAGS_MATCH_ALL,
        tags_match_all.split(",")
      );
    }
    filters.tags_match_all = tags_match_all;
    const tags_match_any = queryParams.tags_match_any ?? "";
    if (tags_match_any) {
      filterForm.setFieldValue(
        filterFormFields.TAGS_MATCH_ANY,
        tags_match_any.split(",")
      );
    }
    filters.tags_match_any = tags_match_any;
    const pageSize = parseInt(queryParams.pageSize ?? 10);
    const fav = queryParams.fav === "true";
    console.log("filters", filters);
    console.log("fav", fav);
    if (fav) {
      console.log("-----------------------------------");
      console.log("setFieldValue", filterFormFields.FAV, true);
      filterForm.setFieldValue(filterFormFields.FAV, true);
    }
    fetchProjects({
      page: queryParams.page ?? 1,
      pageSize: pageSize,
      orderBy: queryParams.orderBy ?? "-id",
      filters: filters,
      fav: fav,
    });
    fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  const [projects, setProjects] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 1,
    current: 1,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState("-id");
  const [loading, setLoading] = useState(false);
  const fetchTags = () => {
    console.log("fetchTags...");
    api.projects.tags().then((res) => {
      console.log("tags response", res.data);
      setProjectTags(
        res.data.map((tag) => ({
          value: tag,
          label: tag,
        }))
      );
    });
  };
  const fetchProjects = ({
    page = 1,
    pageSize = 10,
    orderBy = "-id",
    filters = {},
    fav = false,
  }) => {
    console.log("fetchProjects...");
    console.log(
      "page",
      page,
      "pageSize",
      pageSize,
      "orderBy",
      orderBy,
      "filters",
      filters
    );
    setLoading(true);
    if (fav) {
      api.projects
        .favouriteProjects(page, pageSize, orderBy, filters)
        .then((res) => {
          console.log("projects response", res.data);
          setProjects(res.data.results);
          setPagination((old) => {
            return {
              ...old,
              current: res.data.pagination.current,
              pageSize: pageSize,
              total: res.data.pagination.count,
            };
          });
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
      return;
    } else {
      api.projects
        .projects(page, pageSize, orderBy, filters)
        .then((res) => {
          console.log("projects response", res.data);
          setProjects(res.data.results);
          setPagination((old) => {
            return {
              ...old,
              current: res.data.pagination.current,
              pageSize: pageSize,
              total: res.data.pagination.count,
            };
          });
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    }
  };

  const ProjectDescription = ({ item }) => {
    const [showMore, setShowMore] = useState(false);
    return (
      <div>
        {item.description?.length <= 200 && <div>{item.description}</div>}
        {!showMore && item.description?.length > 200 && (
          <div>
            {item.description?.substring(0, 200)}{" "}
            <Button size="small" onClick={() => setShowMore(true)}>
              Show more
            </Button>
          </div>
        )}
        {showMore && item.description?.length > 200 && (
          <div>
            {item.description}
            <Button size="small" onClick={() => setShowMore(false)}>
              Show less
            </Button>
          </div>
        )}
      </div>
    );
  };

  const hiddenColumns = ["id"];
  const columns = [
    {
      width: 50,
      title: "ID",
      key: "id",
      hidden: hiddenColumns.includes("id"),
      render: (item) => {
        return <span>{item.id}</span>;
      },
    },
    {
      width: 150,
      title: t(TRANSLATIONS.NAME.key),
      key: "name",
      render: (item) => {
        // find user in fav_users
        const favUser = item.fav_users.find(
          (favUser) => user.id === favUser.id
        );
        return (
          <Link to={`/projects/${item.id}`}>
            {item.name}
            <Tooltip title={t(TRANSLATIONS.YOUR_FAVOURITE.key)}>
              <span className="project-fav">{favUser ? "⭐" : ""}</span>
            </Tooltip>
          </Link>
        );
      },
      sortDirections: ["ascend", "descend"],
      sorter: true,
      hidden: hiddenColumns.includes("name"),
      sortOrder:
        orderBy.replace("-", "") === "name"
          ? orderBy.startsWith("-")
            ? "descend"
            : "ascend"
          : null,
    },

    {
      width: 400,
      title: t(TRANSLATIONS.DESCRIPTION.key),
      key: "description",
      render: (item) => {
        return (
          <div>
            <ProjectDescription item={item} />
          </div>
        );
      },
      sorter: false,
      hidden: hiddenColumns.includes("description"),
    },

    {
      width: 200,
      title: t(TRANSLATIONS.TAGS.key),
      render: (item) => {
        return (
          <div>
            {item.tags.map((tag) => (
              <Tag key={tag} color="blue">
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
      hidden: hiddenColumns.includes("tags"),
    },
  ];
  const boxStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    // border: "3px solid #ffafcc",
    marginBottom: 10,
    padding: 10,
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  // TODO: animate ???
  // https://blog.logrocket.com/create-collapsible-react-components-react-collapsed/
  function CollapsibleFilters() {
    const [isExpanded, setExpanded] = useState(
      window.localStorage.getItem("projects_filters_expanded") === "1"
    );
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
    function handleOnClick() {
      const newState = !isExpanded;
      if (newState) {
        window.localStorage.setItem("projects_filters_expanded", "1");
      } else {
        window.localStorage.setItem("projects_filters_expanded", "0");
      }
      setExpanded(!isExpanded);
    }
    return (
      <div className="collapsible">
        <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
          {isExpanded ? (
            <Button type="dark" shape="round">
              {t(TRANSLATIONS.HIDE_FILTERS.key)}
            </Button>
          ) : (
            <Button type="primary" shape="round">
              {t(TRANSLATIONS.SHOW_FILTERS.key)}
            </Button>
          )}
        </div>
        <div {...getCollapseProps()}>
          <div className="content">
            <Form
              {...formItemLayout}
              form={filterForm}
              onFinish={(values) => {
                console.log("onFinish", values);
                const filters = {};
                filters.name_contains =
                  values[filterFormFields.NAME_CONTAINS] ?? "";
                console.log(
                  "tags_match_all",
                  values[filterFormFields.TAGS_MATCH_ALL]
                );
                filters.tags_match_all =
                  values[filterFormFields.TAGS_MATCH_ALL]?.join(",") ?? "";

                console.log(
                  "tags_match_any",
                  values[filterFormFields.TAGS_MATCH_ANY]
                );
                filters.tags_match_any =
                  values[filterFormFields.TAGS_MATCH_ANY]?.join(",") ?? "";

                filters.fav = values[filterFormFields.FAV] ?? false;

                console.log("filters", filters);
                setQueryParams(path, {
                  ...queryParams,
                  ...filters,
                  page: 1,
                });
              }}
            >
              {/* <Flex
                style={boxStyle}
                align={"center"}
                justify={"flex-end"}
                gap={"large"}
              >
                <h2>{t(TRANSLATIONS.FILTERS.key)}</h2>
              </Flex> */}

              <Form.Item
                label={t(TRANSLATIONS.FAVOURITE.key)}
                name={filterFormFields.FAV}
                id={filterFormFields.FAV}
                initialValue={false}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={t(TRANSLATIONS.PROJECT_NAME.key)}
                name={filterFormFields.NAME_CONTAINS}
                id={filterFormFields.NAME_CONTAINS}
              >
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item
                label={t(TRANSLATIONS.TAGS_MATCH_ALL.key)}
                name={filterFormFields.TAGS_MATCH_ALL}
                id={filterFormFields.TAGS_MATCH_ALL}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  defaultValue={[]}
                  options={projectTags}
                />
              </Form.Item>

              <Form.Item
                label={t(TRANSLATIONS.TAGS_MATCH_ANY.key)}
                name={filterFormFields.TAGS_MATCH_ANY}
                id={filterFormFields.TAGS_MATCH_ANY}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  defaultValue={[]}
                  options={projectTags}
                />
              </Form.Item>

              <Flex
                style={boxStyle}
                align={"center"}
                justify={"flex-end"}
                gap={"large"}
              >
                <Button
                  type="dark"
                  itemType="button"
                  onClick={() => {
                    setQueryParams(path, {
                      ...queryParams,
                      name_contains: "",
                      tags_match_all: "",
                      tags_match_any: "",
                    });
                    filterForm.resetFields();
                  }}
                >
                  {t(TRANSLATIONS.CLEAR.key)}
                </Button>
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" loading={loading}>
                  {t(TRANSLATIONS.SEARCH.key)}
                </Button>
              </Flex>
            </Form>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Row>
      <Col span={24}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12} offset={0}>
            <CollapsibleFilters />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <Flex align={"center"} justify={"flex-end"} gap={"middle"}>
              {pagination.total > 0 && (
                <Pagination
                  onChange={(page, pageSize) => {
                    console.log("onChange pagination", page, pageSize);
                    setQueryParams(path, {
                      ...queryParams,
                      page: page,
                      pageSize: pageSize,
                    });
                  }}
                  current={pagination.current}
                  defaultPageSize={pagination.pageSize}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  showTotal={(total) =>
                    `${t(TRANSLATIONS.TOTAL.key)} ${total} ${t(
                      TRANSLATIONS.ITEMS.key
                    )}`
                  }
                  showSizeChanger={false}
                  showLessItems={true}
                  itemRender={(current, type, originalElement) => {
                    if (type === "prev") {
                      return (
                        <Button>
                          <LeftOutlined />
                        </Button>
                      );
                    }
                    if (type === "next") {
                      return (
                        <Button>
                          <RightOutlined />
                        </Button>
                      );
                    }
                    return originalElement;
                  }}
                />
              )}
              <Select
                style={{ width: 150 }}
                defaultValue={pagination.pageSize}
                options={[1, 10, 25, 50].map((value) => ({
                  value: value,
                  label: (
                    <span>
                      {value} {t(TRANSLATIONS.PER_PAGE.key)}
                    </span>
                  ),
                }))}
                onChange={(value) => {
                  console.log("onChange select per page", value);
                  setQueryParams(path, {
                    ...queryParams,
                    page: 1,
                    pageSize: value,
                  });
                }}
                value={pagination.pageSize}
              />
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              id="agent-teams"
              pagination={false}
              size="small"
              columns={columns}
              dataSource={projects ?? []}
              loading={loading}
              showSorterTooltip={true}
              locale={{
                triggerAsc: `${t(TRANSLATIONS.SORTING_ASCENDING_LABEL.key)}`,
                triggerDesc: `${t(TRANSLATIONS.SORTING_DESCENDING_LABEL.key)}`,
                cancelSort: `${t(TRANSLATIONS.SORTING_CANCEL_LABEL.key)}`,
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={`${t(TRANSLATIONS.EMPTY_LIST_TITLE.key)}`}
                  />
                ),
              }}
              rowKey="id"
              bordered
              onChange={(pagination, filters, sorter) => {
                console.log("only sorter", sorter);
                const sortedOrder = sorter?.order ?? "";
                const sortedField = sorter?.columnKey ?? "";
                const orderBy =
                  sortedOrder === "ascend"
                    ? `${sortedField}`
                    : sortedOrder === "descend"
                    ? `-${sortedField}`
                    : "-id";
                console.log("orderBy", orderBy);
                setOrderBy(orderBy);
                setQueryParams(path, {
                  ...queryParams,
                  orderBy: orderBy,
                });
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ProjectsTableSection;
